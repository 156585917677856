:root {
  --aside-width: 400px;
  --cart-aside-summary-height-with-discount: 300px;
  --cart-aside-summary-height: 250px;
  --grid-item-width: 355px;
  --header-height: 90px;
  --color-dark: #000;
  --color-light: #fff;
}

/* img {
  border-radius: 4px;
} */

/*
* --------------------------------------------------
* components/Aside
* --------------------------------------------------
*/

aside {
  background: var(--color-light);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  height: 100dvh;
  max-width: var(--aside-width);
  min-width: var(--aside-width);
  position: fixed;
  right: calc(-1 * var(--aside-width));
  top: 0;
  transition: transform 200ms ease-in-out;
}

aside header {
  align-items: center;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
}

aside header h3 {
  margin: 0;
}

aside header .close {
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  transition: all 200ms;
  width: 20px;
}

aside header .close:hover {
  opacity: 1;
}

aside header h2 {
  margin-bottom: 0.6rem;
  margin-top: 0;
}

aside main {
}

aside p {
  margin: 0 0 0.25rem;
}

aside p:last-child {
  margin: 0;
}

aside li {
  margin-bottom: 0.125rem;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in-out;
  transition: opacity 400ms;
  visibility: hidden;
  z-index: 10;
}

.overlay .close-outside {
  background: transparent;
  border: none;
  color: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - var(--aside-width));
}

.overlay .light {
  background: rgba(255, 255, 255, 0.5);
}

.overlay .cancel {
  cursor: default;
  height: 100%;
  position: absolute;
  width: 100%;
}

.overlay:target {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}
/* reveal aside */
.overlay:target aside {
  transform: translateX(calc(var(--aside-width) * -1));
}

/*
* --------------------------------------------------
* components/Header
* --------------------------------------------------
*/
.header {
  align-items: center;
  display: flex;
  height: var(--header-height);
  padding: 0 1rem;
  position: absolute;
  top: 0;
  z-index: 1;
  opacity: 0;
}

.header-menu-mobile {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
}

.header-menu-desktop {
  display: none;
  grid-gap: 1rem;
  @media (min-width: 45em) {
    display: flex;
    grid-gap: 1rem;
    margin-left: 3rem;
  }
}

.header-menu-item {
  cursor: pointer;
}


/*
* --------------------------------------------------
* components/Footer
* --------------------------------------------------
*/
.footer {
  background: var(--color-dark);
  margin-top: auto;
}

.footer-menu {
  align-items: center;
  display: flex;
  grid-gap: 1rem;
  padding: 1rem;
}

.footer-menu a {
  color: var(--color-light);
}

/*
* --------------------------------------------------
* components/Cart
* --------------------------------------------------
*/
.cart-main {
  height: 100%;
  /* max-height: calc(100vh - var(--cart-aside-summary-height)); */
  overflow-y: auto;
  width: auto;
}

.cart-main.with-discount {
  max-height: calc(100vh - var(--cart-aside-summary-height-with-discount));
}

.cart-line {
  display: flex;
  padding: 0.75rem 0;
}

.cart-line img {
  height: 100%;
  display: block;
  margin-right: 0.75rem;
}

.cart-summary-page {
  position: relative;
}

.cart-summary-aside {
  background: white;
  border-top: 1px solid var(--color-dark);
  bottom: 0;
  padding-top: 0.75rem;
  position: absolute;
  width: calc(var(--aside-width) - 40px);
}

.cart-line-quantity {
  display: flex;
}

.cart-discount {
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
}

.cart-subtotal {
  align-items: center;
  display: flex;
}
/*
* --------------------------------------------------
* components/Search
* --------------------------------------------------
*/
.predictive-search {
  height: calc(100vh - var(--header-height) - 40px);
  overflow-y: auto;
}

.predictive-search-form {
  background: var(--color-light);
  position: sticky;
  top: 0;
}

.predictive-search-result {
  margin-bottom: 2rem;
}

.predictive-search-result h5 {
  text-transform: uppercase;
}

.predictive-search-result-item {
  margin-bottom: 0.5rem;
}

.predictive-search-result-item a {
  align-items: center;
  display: flex;
}

.predictive-search-result-item a img {
  margin-right: 0.75rem;
  height: 100%;
}

.search-result {
  margin-bottom: 1.5rem;
}

.search-results-item {
  margin-bottom: 0.5rem;
}

.search-results-item a {
  display: flex;
  flex: row;
  align-items: center;
  gap: 1rem;
}



/*
* --------------------------------------------------
* routes/__index
* --------------------------------------------------
*/
.featured-collection {
  display: block;
  margin-bottom: 2rem;
  position: relative;
}

.featured-collection-image {
  aspect-ratio: 1 / 1;
  @media (min-width: 45em) {
    aspect-ratio: 16 / 9;
  }
}

.featured-collection img {
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

.recommended-products-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 45em) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.recommended-product img {
  height: auto;
}

/*
* --------------------------------------------------
* routes/collections._index.tsx
* --------------------------------------------------
*/
.collections-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));
  margin-bottom: 2rem;
}

.collection-item img {
  height: auto;
}

/*
* --------------------------------------------------
* routes/collections.$handle.tsx
* --------------------------------------------------
*/
.collection-description {
  margin-bottom: 1rem;
  max-width: 95%;
  @media (min-width: 45em) {
    max-width: 600px;
  }
}

.products-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));
  margin-bottom: 2rem;
}

.product-item img {
  height: auto;
  width: 100%;
}

/*
* --------------------------------------------------
* routes/products.$handle.tsx
* --------------------------------------------------
*/
.product {
  display: grid;
}

.product h1 {
  margin-top: 0;
}

.product-main {
  align-self: start;
  position: sticky;
  top: 6rem;
}

.product-price-on-sale {
  display: flex;
  grid-gap: 0.5rem;
}

.product-price-on-sale s {
  opacity: 0.5;
}

.product-options-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.75rem;
}

.product-options-item {
  padding: 0.25rem 0.5rem;
}

/*
* --------------------------------------------------
* routes/blog._index.tsx
* --------------------------------------------------
*/
.blog-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));
  margin-bottom: 2rem;
}

.blog-article-image {
  aspect-ratio: 3/2;
  display: block;
}

.blog-article-image img {
  height: 100%;
}

/*
* --------------------------------------------------
* routes/blog.$articlehandle.tsx
* --------------------------------------------------
*/
.article img {
  height: auto;
  width: 100%;
}

/*
* --------------------------------------------------
* routes/account
* --------------------------------------------------
*/

.account-logout {
  display: inline-block;
}

select#LanguageSwitcherSelect {
  background: none;
}
